import Vue from 'vue';
import Vuex from 'vuex';
import api from "@/axios/api";
import {TOKEN_API} from "@/api/token-api";
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        user: JSON.parse(localStorage.getItem('user')) || null
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        logout(state) {
            state.user = null;
            localStorage.removeItem('user');
        }
    },
    actions: {
        async checkToken({ state }) {
            if (!state.user?.token) return false;
            try {
                const res = await api.get(TOKEN_API.CHECK_TOKEN);
                console.log(res)
                return res.data;
            } catch (error) {
                return false;
            }
        }
    }
});