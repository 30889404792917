<template>
  <header class="header">
    <div class="header-container">
      <!-- Logo区域 -->
      <div class="logo-section">
        <img 
          class="logo-image"
          src="../../../public/shuyouwang2.jpg" 
          :class="{ 'animate__animated animate__headShake': isHover }"
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
          alt="网站Logo"
        />
      </div>

      <!-- 导航菜单 -->
      <nav class="nav-section">
        <a
          v-for="item of navList"
          :key="item.id"
          class="nav-item"
          :class="{ 'active': currentPath === item.url }"
          @click="push(item.url)"
        >
          {{ item.title }}
        </a>
      </nav>

      <!-- 用户区域 -->
      <div class="user-section">
        <el-dropdown @command="handleCommand" trigger="click">
          <div class="user-profile">
            <img class="avatar" :src="user.avatarUrl || 'https://img.zcool.cn/community/037af5655dead160000015995e59c07.jpg'" alt="用户头像">
            <span class="username">{{ user.nickname || '未登录' }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <el-dropdown-item command="a">
              <i class="el-icon-user"></i> 个人中心
            </el-dropdown-item>
            <el-dropdown-item command="b" divided>
              <i class="el-icon-switch-button"></i> 退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </header>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
import 'animate.css';
import store from "@/store";

export default {
  computed: {
    store() {
      return store
    },
    currentPath() {
      return this.$route.name;
    }
  },
  data() {
    return {
      isHover: false,
      user: {},
      navList: [
        {
          id: "1",
          title: "首页",
          url: "home",
        },
        {
          id: "2",
          title: "游戏推荐",
          url: "details",
        },
        {
          id: "3",
          title: "查游戏",
          url: "pcgame",
        },
        {
          id: "4",
          title: "数友趣闻",
          url: "informations",
        },
        {
          id: "5",
          title: "图表数据",
          url: "steamData",
        },
        {
          id: "6",
          title: "聊天室",
          url: "chatroom",
        },
      ],
    };
  },
  props: {
    index: {
      type: String,
      default: "0",
    },
  },
  methods: {
    handleCommand(command) {
      if (command === "a") {
        this.push("userdetail")
      }
      else if (command === "b") {
        this.quit()
      }
    },
    quit() {
      this.user.nickname = null;
      store.commit('logout');
      this.push("home");
      this.$message.success("登出成功");
    },
    push(val) {
      this.$router.push({
        name: val
      })
    }
  },
  created() {
    let user = localStorage.getItem("user") || "{}"
    this.user = JSON.parse(user)
  },
};
</script>

<style scoped>
.header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(36, 41, 47, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

/* Logo样式 */
.logo-section {
  flex: 0 0 auto;
}

.logo-image {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.logo-image:hover {
  transform: scale(1.05);
}

/* 导航菜单样式 */
.nav-section {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.nav-item {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding: 8px 0;
  position: relative;
  transition: all 0.3s ease;
}

.nav-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ff6602;
  transition: width 0.3s ease;
}

.nav-item:hover::after,
.nav-item.active::after {
  width: 100%;
}

.nav-item:hover,
.nav-item.active {
  color: #ff6602;
  cursor: pointer;
}

/* 用户区域样式 */
.user-section {
  flex: 0 0 auto;
}

.user-profile {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.user-profile:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  transition: border-color 0.3s ease;
}

.user-profile:hover .avatar {
  border-color: #ff6602;
}

.username {
  color: #fff;
  margin: 0 8px;
  font-size: 14px;
}

/* 下拉菜单样式 */
.user-dropdown {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.user-dropdown .el-dropdown-item {
  padding: 8px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.user-dropdown .el-dropdown-item i {
  font-size: 16px;
}

.user-dropdown .el-dropdown-item:hover {
  background-color: #f5f5f5;
  color: #ff6602;
}

/* 响应式设计 */
@media screen and (max-width: 768px) {
  .header-container {
    padding: 0 10px;
  }

  .nav-section {
    gap: 15px;
  }

  .nav-item {
    font-size: 14px;
  }

  .username {
    display: none;
  }
}
</style>
